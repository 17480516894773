import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { CSVLink } from "react-csv";
import { isEmpty } from "lodash";

import { UiButton } from "../../../../components/wilson-ui/buttons";
import { Text } from "../../../../components/wilson-ui/text";
import { useWidgetsHeaderStyles } from "./widget-header-styles.hook";
import { FlexBox } from "../../../../components/wilson-ui/box";
import { usePageStyles } from "../../../pages-styles.hook";
import { ROUTING_CONFIG } from "../../../../constants/routing-config/routing-config";
import { WidgetTabValue } from "../../../../types";
import { DownloadCsvModal } from "../../../../components/wilson-ui/modals";
import {
  useWidgetsDownloadCSV,
  useWidgetsDownloadCurrentCSV,
} from "../../hooks";

interface IProps {
  tab: string;
  visibleWidgetsId: any[];
  name: string;
  order: string | null;
  sortType: Array<string>;
}

export const WidgetsHeader: React.FC<IProps> = ({
  tab,
  visibleWidgetsId,
  name,
  order,
  sortType,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentCSV, setCurrentCSV] = useState([]);
  const [fullCSV, setFullCSV] = useState([]);
  const classes = useWidgetsHeaderStyles();
  const pageClasses = usePageStyles();
  const intl = useIntl();
  const history = useHistory();

  const csvInstance: any = useRef();
  useEffect(() => {
    if (
      !isEmpty(currentCSV) &&
      csvInstance?.current &&
      csvInstance?.current?.link
    ) {
      setTimeout(() => {
        csvInstance.current.link.click();
        setCurrentCSV([]);
      });
    }
  }, [currentCSV]);

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const downloadCurrentCSV = async () => {
    useWidgetsDownloadCurrentCSV(
      visibleWidgetsId = [].concat(...visibleWidgetsId),
      name,
      order,
      sortType,
      setCurrentCSV
    );
  };

  const downloadCSV = async () =>
    useWidgetsDownloadCSV(
      visibleWidgetsId = [].concat(...visibleWidgetsId),
      name,
      order,
      sortType,
      setCurrentCSV,
      setFullCSV,
      toggleModal
    );

  const createWidgetHandler = () =>
    history.push(ROUTING_CONFIG.createWidget, null);

  const modal = isModalOpen && (
    <DownloadCsvModal
      closeHandler={toggleModal}
      currentCSV={currentCSV}
      fullCSV={fullCSV}
      currentName="widgets-current-list"
      fullName="widgets-full-list"
    />
  );

  return (
    <FlexBox className={`${classes.container} ${pageClasses.container}`}>
      <Text className={pageClasses.pageTitle}>
        {intl.formatMessage({ id: "feed_widgets" })}
      </Text>
      <FlexBox className={classes.buttons}>
        {tab === WidgetTabValue.feedOrder ? (
          <>
            <UiButton buttonType="white" onClick={downloadCurrentCSV}>
              {intl.formatMessage({ id: "export_current_view" })}
            </UiButton>
            {currentCSV && (
              <CSVLink
                filename="widgets-current-list"
                data={currentCSV}
                ref={csvInstance}
                enclosingCharacter={` `}
              />
            )}
          </>
        ) : (
          <UiButton buttonType="white" onClick={downloadCSV}>
            {intl.formatMessage({ id: "export" })}
          </UiButton>
        )}
        <UiButton buttonType="black" onClick={createWidgetHandler}>
          {intl.formatMessage({ id: "create_a_widget" })}
        </UiButton>
      </FlexBox>
      {modal}
    </FlexBox>
  );
};
