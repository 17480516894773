import React from "react";
import { useIntl } from "react-intl";
import { CSVLink } from "react-csv";
import { UiButton } from "../../buttons";
import { useDownloadCSVdModalStyles } from "./download-csv-modal-styles.hook";
import { UiModal } from "../modal";

interface IProps {
  closeHandler: () => void;
  currentCSV: Array<Array<any>> | string;
  fullCSV: Array<Array<any>> | string;
  currentName: string;
  fullName: string;
}

export const DownloadCsvModal: React.FC<IProps> = ({
  closeHandler,
  currentCSV,
  fullCSV,
  currentName,
  fullName,
}) => {
  const intl = useIntl();
  const classes = useDownloadCSVdModalStyles();

  return (
    <UiModal width="600px" closeHandler={closeHandler}>
      <div className={classes.header}>
        <h3>{intl.formatMessage({ id: "download_table" })}</h3>
      </div>
      <div className={classes.container}>
        <CSVLink filename={currentName} data={currentCSV} enclosingCharacter={` `}>
          <UiButton buttonType="black">
            {intl.formatMessage({ id: "export_current_view" })}
          </UiButton>
        </CSVLink>
        <CSVLink filename={fullName} data={fullCSV} enclosingCharacter={` `}>
          <UiButton buttonType="white">
            {intl.formatMessage({ id: "export_all" })}
          </UiButton>
        </CSVLink>
      </div>
    </UiModal>
  );
};
