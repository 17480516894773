import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { toast } from "react-toastify";
import { CSVLink } from "react-csv";
import { Dayjs } from "dayjs";
import { UiModal } from "../modal";
import { UiButton } from "../../buttons";
import { DateRange } from "@mui/x-date-pickers-pro/DateRangePicker";
import { useDownloadCSVdModalStyles } from "./download-csv-modal-styles.hook";
import { CustomDateRangePickerDay } from "./ranger-time-modal";

interface IProps {
  toggle: () => void;
  currentName: string;
  fullName: string;
  downloadCSV: () => any;
  downloadCSVAll: (dateRange: DateRange<Dayjs>) => any;
}

export const DownloadCsvAsyncModal: React.FC<IProps> = ({
  currentName,
  fullName,
  toggle,
  downloadCSV,
  downloadCSVAll
}) => {
  const intl = useIntl();
  const classes = useDownloadCSVdModalStyles();
  const [csvLoading, setCSVLoading] = useState(false);
  const [csvLoadingAll, setCSVLoadingAll] = useState(false);
  const [dataCSV, setDataCSV] = useState<any>([[]]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dateRange, setDateRange] = useState<DateRange<Dayjs>>([null, null]);

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  useEffect(() => {
    if (!csvLoadingAll) return;
    async function fetchMyAPI() {
      const data = await downloadCSVAll(dateRange);
      setDataCSV(data);
    }
    fetchMyAPI();
  }, [csvLoadingAll]);

  useEffect(() => {
    if (!csvLoading) return;
    async function fetchMyAPI() {
      const data = await downloadCSV();
      setDataCSV(data);
    }
    fetchMyAPI();
  }, [csvLoading]);

  useEffect(() => {
    if (dataCSV && dataCSV.length > 0 && (csvLoading || csvLoadingAll)) {
      (document as any)?.getElementById("download-csv").click();
      new Promise((r) => setTimeout(r, 2000))
      setCSVLoading(false);
      setCSVLoadingAll(false);
    }
    if (!dataCSV.length) {
      setCSVLoading(false);
      setCSVLoadingAll(false);
      toast(intl.formatMessage({ id: "file_empty" }));
    }
  }, [dataCSV]);

  const modal = isModalOpen && (
    <CustomDateRangePickerDay
      toggle={toggleModal}
      classes={classes}
      onDowload={dateRange => {
        toggleModal();
        if (!csvLoadingAll) {
          setDateRange(dateRange);
          setCSVLoadingAll(true);
        }
      }}
    />
  );

  const loading = csvLoading || csvLoadingAll;
  return (
    <UiModal width="600px" closeHandler={toggle}>
      <div className={classes.header}>
        <h3>{intl.formatMessage({ id: "download_table" })}</h3>
      </div>
      <div className={classes.container}>
        <UiButton
          buttonType="black"
          disabled={loading}
          onClick={() => {
            if (!csvLoading) setCSVLoading(true);
          }}
        >
          {intl.formatMessage({ id: loading ? "exporting": "export_current_view" })}
        </UiButton>     
        <UiButton
          buttonType="white"
          disabled={loading}
          onClick={toggleModal}
          >
          {intl.formatMessage({ id: loading ? "exporting": "export_all" })}
        </UiButton>
        <CSVLink
          id="download-csv"
          data={dataCSV}
          filename={csvLoading ? currentName: fullName}
          enclosingCharacter={` `}
        />
      </div>
      {modal}
    </UiModal>
  );
};

