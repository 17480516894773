import React from "react";
import { useIntl } from "react-intl";
import Box from '@mui/material/Box';
import { Dayjs } from 'dayjs';
import { DateRange } from '@mui/x-date-pickers-pro/DateRangePicker';
import { CSVLink } from "react-csv";
import { toast } from "react-toastify";
import { UiButton } from "../../buttons";
import { useUsersDownloadCSV } from "../../../../pages/users/hooks";
import { CheckboxRed } from "../../../wilson-ui/checkboxes";
import { FlexBox } from "../../../../components/wilson-ui/box";
import { useDownloadUserCSVdModalStyles } from "./download-user-csv-modal-styles.hook";
import { UiModal } from "../modal";
import iconModalExport from "../../../../assets/images/iconModalExport.png";
import iconAllUsers from "../../../../assets/images/iconAllUsers.png";
import iconInterestUsers from "../../../../assets/images/iconInterestUsers.png";
import iconXClose from "../../../../assets/images/xClose.png";
import { BasicDateRangePicker } from "./ranger-date-basic";

interface IProps {
  length: number;
  sortByName: string;
  order: string;
  term?: string;
  toggle: () => void;
}

export const DownloadUsersCsvModal: React.FC<IProps> = ({
  toggle,
  order,
  term,
  sortByName,
  length
}) => {
  const intl = useIntl();
  const classes = useDownloadUserCSVdModalStyles();
  const [isInterests, setIsInterests] = React.useState<boolean>(false);
  const [date, setDate] = React.useState<DateRange<Dayjs>>([null, null]);
  const [dataCSV, setDataCSV] = React.useState<any>([[]]);
  const [csvLoading, setCSVLoading] = React.useState(false);

  const handleSelected = (isInterests: boolean) => {
    setIsInterests(isInterests);
  }

  const handleChangeDate = (date: DateRange<Dayjs>) => {
    setDate(date);
  }

  const downloadCSV = async () => {
    try {
      return await useUsersDownloadCSV(
        length,
        date?.[0]?.toDate() || null,
        date?.[1]?.toDate() || null,
        order,
        sortByName,
        term,
        isInterests
      );
    } catch (error) {
      return [[]];
    }
  };

  React.useEffect(() => {
    if (!csvLoading) return;
    async function fetchMyAPI() {
      const data = await downloadCSV();
      setDataCSV(data);
    }
    fetchMyAPI();
  }, [csvLoading]);

  React.useEffect(() => {
    if (dataCSV && dataCSV.length > 0 && csvLoading) {
      (document as any)?.getElementById("download-users-csv").click();
      new Promise((r) => setTimeout(r, 2000))
      setCSVLoading(false);
    }
    if (!dataCSV.length) {
      setCSVLoading(false);
      toast(intl.formatMessage({ id: "file_empty" }));
    }
  }, [dataCSV]);

  return (
    <UiModal width="480px" closeHandler={toggle}>
      <div className={classes.container}>
        <div className={classes.header}>
          <FlexBox content="center" className={classes.headerIcon}>
            <img src={iconModalExport} style={{ width: "84px" }} />
          </FlexBox>
          <div className={classes.headerInner}>
            <div className={classes.headerTitle}>
              <h3>{intl.formatMessage({ id: "export_data" })}</h3>
              <h5>What would you like to export?</h5>
            </div>
            <BasicDateRangePicker onChangeDate={handleChangeDate} />
          </div>
          <div className={classes.headerClose} onClick={toggle}>
            <img src={iconXClose} style={{ width: "20px" }} />
          </div>
        </div>
        <ul className={classes.optionsInner}>
          <li
            className={!isInterests ? "active" : ""}
            onClick={() => handleSelected(false)}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <img
                src={iconAllUsers}
                style={{ width: "32px", height: "32px" }}
              />
              <div className="label">
                <p>All User Data</p>
                <span>Exports all data irregardless of applied filters</span>
              </div>
            </Box>
            <CheckboxRed checked={!isInterests} />
          </li>
          <li
            className={isInterests ? "active" : ""}
            onClick={() => handleSelected(true)}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <img
                src={iconInterestUsers}
                style={{ width: "32px", height: "32px" }}
              />
              <div className="label">
                <p>User Interest</p>
                <span>Provides insight into trending user interest</span>
              </div>
            </Box>
            <CheckboxRed checked={isInterests} />
          </li>
        </ul>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            padding: "24px",
          }}
        >
          <UiButton
            buttonType="black"
            disabled={csvLoading}
            onClick={toggle}
            style={{
              width: "50%",
              background: "inherit",
              color: "#344054",
              border: "1px solid #D0D5DD",
            }}
          >
            {intl.formatMessage({ id: csvLoading ? "exporting" : "cancel" })}
          </UiButton>
          <Box sx={{ mx: 1 }}></Box>
          <UiButton
            buttonType="white"
            disabled={csvLoading}
            onClick={() => {
              if (!csvLoading) setCSVLoading(true);
            }}
            style={{
              width: "50%",
              background: "#CF102D",
              color: "#fff",
              border: "1px solid #CF102D",
            }}
          >
            {intl.formatMessage({
              id: csvLoading ? "exporting" : "export_one_file",
            })}
          </UiButton>
        </Box>
        <CSVLink
          id="download-users-csv"
          data={dataCSV}
          filename="users-full-list"
          enclosingCharacter={` `}
        />
      </div>
    </UiModal>
  );
};
